.table {
  min-width: 700px;
}

.appbar {
  background-color: #095c75;
}

.title {
  flex-grow: 1;
  font-weight: initial;
  margin-left: 10px;
}

.head {
  display: flex;
}

.rightAction {
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
}

.taskTransaction {
  display: flex;
  align-items: center;
  height: 20px;
  padding: 10px;
  margin: 15px;
  text-transform: capitalize;
}

.itemMargin {
  margin-left: 2;
}

.cell {
  height: 20px;
  padding: 10px;
  align-items: center;
  margin: 15px;
  text-transform: capitalize;
}

.actions {
  width: 100px !important;
}

.tableTitle {
  display: flex;
  justify-content: space-between;
}

.tileBody {
  display: flex;
  align-items: center;
}

thead tr th {
  border-top: 1px solid #095c75;
  background-color: #f5f4f4 !important;
  border-bottom: 2px solid #9e9a9a !important;
}

.amount {
  text-align: right !important;
}

.taskLegend {
  display: flex;
  align-items: center;
}

.box {
  width: 20px;
  height: 20px;
  background: blue;
}

.circle {
  width: 10px;
  height: 8px;
  border-radius: 50%;
  background: #095c75;
  margin-right: 14px;
  margin-bottom: 4px;
  margin-left: 10px;
}

.tableDesc {
  white-space: nowrap;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.searchBar {
  display: flex;
  align-items: center;
}

.groupTileSearch {
  display: flex;
  width: 70%;
  align-items: inherit;
  justify-content: space-between;
}

.searchBody {
  width: 630px;
  margin-top: 5px;
}

.searchList {
  display: flex;
}

.advancedSearch {
  width: 220px;
}

.searchField {
  margin-right: 10px !important;
}

.duration {
  width: 50px !important;
}

.filedAt {
  width: 90px;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.paginationWidth {
  width: 375px;
}

.showButtonTasksBody button {
  border-radius: 50px;
}

.link {
  color: black;
  text-decoration: none;
}

.link:hover {
  color: #095c75;
}

.toggleButton {
  border-radius: 50px !important;
  background-color: #095c75 !important;
  color: white !important;
  margin-left: 12px !important;
}

.checkColor {
  color: #1f9a09;
}

.clearColor {
  color: #ff0404;
}

.remarksEllipsis {
  white-space: nowrap;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.customHeader {
  width: 50%;
}

.customHeader th {
  border: none;
  border-bottom: none !important;
  font-weight: bold;
}

.accounCode {
  width: 160px;
  padding: 0 10px;
}

.accounName {
  width: 200px;
}

.debit {
  width: 100px;
  text-align: right !important;
}

.credit {
  width: 150px;
  text-align: right !important;
}

.customRow tr {
  width: 50% !important;
}

.customRowBody {
  padding: 0 10px !important;
}

.tableBody tr {
  vertical-align: top !important;
}

.tableBody tr:hover {
  cursor: pointer;
}

.tableBody .table:hover {
  cursor: not-allowed !important;
}

.actionButtonHoverable {
  position: absolute;
  right: 0 !important;
  border-bottom: none !important;
  opacity: 0;
  z-index: 10;
}

.actionButtonHoverable td {
  border-bottom: none !important;
  transition: opacity 1s;
}

.actionButtonHoverable:hover {
  opacity: 1;
}

.tableBody tr:hover + .actionButtonHoverable {
  opacity: 1;
  transition: opacity 1s;
}

.notLinkable td {
  /*pointer-events: none;*/
  vertical-align: baseline !important;
}

.buttonDisbursement {
  width: 60px;
  display: flex;
  justify-content: space-between;
}

.linkable td {
  pointer-events: auto !important;
}

.fullWitdActionHoverable {
  position: absolute;
  width: 100%;
  right: 0 !important;
  border-bottom: none !important;
  opacity: 0;
  z-index: 10;
}

.fullWitdActionHoverable:hover {
  opacity: 1;
  transition: opacity 1s;
}

.fullWitdActionHoverable td {
  border-bottom: none !important;
}

tr.fullWitdActionHoverable:hover {
  opacity: 1;
  transition: opacity 1s;
}

.tableBody tr:hover .fullWitdActionHoverable {
  opacity: 1;
  transition: opacity 1s;
}

.groupByName {
  width: 260px !important;
}

.leave_type {
  padding-left: 0 !important;
  width: 110px;
}

.inclusive_dates {
  width: 160px !important;
}

.without_pay {
  width: 110px;
}

.reason {
  width: 250px;
}

.officer {
  width: 265px !important;
}

.customCell th {
  border: none !important;
}

.tableTitleNone {
  display: none;
}

.incomeCell th {
  border-bottom: none !important;
  border-top: none !important;
  width: 100px !important;
}

.incomegroupCell th {
  height: 20px;
  border-bottom: none !important;
}

.tableHeadBody tr th {
  vertical-align: top !important;
  padding: 3px 5px !important;
}

th.groupHeader th {
  border: none !important;
}

.incomeCell th:first-child {
  width: 100px !important;
}

.incomeCell div {
  border-bottom: 1px solid;
}

.incomeCell {
  padding: 0;
  font-weight: bold !important;
  text-align: center !important;
  height: 12px !important;
}

.incomeCell th:last-child {
  padding-right: 33px;
}

.incomegroupCell {
  padding: 0;
  font-weight: bold !important;
  text-align: center !important;
  height: 12px !important;
}

.incomegroupCell th:last-child {
  padding-right: 33px !important;
  padding-left: 34px;
}

.TableBody {
  text-transform: capitalize !important;
}

.employeeRef {
  padding-right: 0 !important;
  padding-top: 10px;
}

.taxStatus {
  width: 100px !important;
  padding-top: 10px;
}

.totalTextAlignRight {
  text-align: right !important;
  border-bottom: double !important;
}

.groupcell {
  width: 140px !important;
  position: sticky;
  text-transform: capitalize !important;
}

.dataPaymentBody {
  padding: 0 !important;
  width: 264px;
}

.paymentDate {
  border-bottom: none !important;
}

.paymentAmount {
  width: 126px;
  border-bottom: none !important;
}

.accountCell {
  width: 220px !important;
  text-transform: capitalize !important;
}

.withTax {
  white-space: nowrap;
}

.sameHeader {
  width: 300px !important;
}

.withS {
  text-transform: lowercase !important;
}

.nameFormal {
  width: 300px !important;
  border: none !important;
}

.position {
  width: 350px !important;
  border: none !important;
}

.offNum {
  width: 150px !important;
  border: none !important;
}

.directoryClassName {
  width: 100px !important;
}

.email {
  width: 150px !important;
  border: none !important;
}

tr.notLinkable:hover .fullWitdActionHoverable {
  opacity: 1;
  transition: opacity 1s;
}

.notLinkable td:last-child {
  pointer-events: visible !important;
  cursor: default;
  text-align: right;
}

.linkable td:last-child {
  pointer-events: visible !important;
  cursor: default;
  text-align: right;
}

tr.linkable:hover .actionButtonHoverable {
  opacity: 1;
  transition: opacity 1s;
}

tr.notLinkable:hover .actionButtonHoverable {
  opacity: 1;
  transition: opacity 1s;
}

.imageCell {
  height: 20px;
  padding: 10px;
  align-items: center;
  margin: 15px;
  text-transform: capitalize;
  pointer-events: visible !important;
}

.fullWitdActionGroupByHoverable {
  position: absolute;
  right: 0 !important;
  border-bottom: none !important;
  opacity: 0;
  z-index: 10;
}

.groupByTableBody:hover .fullWitdActionGroupByHoverable {
  opacity: 1;
  transition: opacity 1s;
}

.actionButton {
  display: flex !important;
  justify-content: flex-end !important;
  margin-top: 10px;
}

.actionButton button {
  border-radius: 20px !important;
  margin: 5px;
}

.tableBodyForPdf {
  overflow: hidden !important;
}