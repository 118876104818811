.home {
  color: #fff;
}

.textFields {
  margin: 5px 0 !important;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.error {
  color: red !important;
  margin: 0 !important;
  margin-top: -8px !important;
}

.actionButton button {
  border-radius: 20px !important;
}

.leftMargin {
  margin-left: 8px;
}

.leftTextField {
  margin-right: 5px !important;

}

.rightTextField {
  margin-left: 5px !important;

}

.flex {
  display: flex;
  margin: 5px 0 !important;
  align-items: center;
  justify-content: space-between !important;
}

.autoCompleteField {
  width: 100%;
  margin: 5px 2px !important;
}

.leftText {
  margin-right: 5px !important;
  text-transform: capitalize !important;
}

.rightText {
  margin-left: 5px !important;
  text-transform: capitalize !important;
}

.capitalize {
  text-transform: capitalize;
}

.amountBody {
  margin-left: 5px !important;
}

.amountBody input {
  text-align: right !important;
}


.group2Textfield {
  display: flex;
}

.group2Textfield .textFields {
  margin: 10px 5px !important;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  font-size: 19px;
  align-items: center;
  background: cadetblue;
  color: aliceblue;
  font-weight: 600;
  padding: 0 10px;
  margin-top: 10px;
}

.showTitleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -20px;
}

.detailsBody {
  display: flex;
  font-size: 16px;
}

.leftShowDetails {
  width: 50%;
  margin-right: 10px;
}

.rightShowDetails {
  width: 50%;
}

.showTitleHeader h2 {
  margin: 0;
}

.detailList {
  display: flex;
  font-size: 16px;
}

.detailList div:first-child {
  min-width: 255px;
}

.detailList div:last-child {
  text-transform: capitalize;
}

.groupButtonShow {
  display: flex;
  align-items: center;
}

.avatar {
  height: 100px !important;
  width: 130px !important;
  margin-right: -20px !important;
}

.headers {
  display: flex;
  align-items: center;
  font-size: 23px;
}

.headers :first-child {
  width: 200px;
}

.tabBody {
  margin-top: 10px;
}

.openSidenav {
  width: 94%;
}

.openSidenavTabBody {
  margin-top: -25px;
}

.tabBodyMemo {
  margin-top: 45px;
}

.tabBodyEmployee {
  margin-top: 25px;
}

.tabHead {
  position: absolute;
  width: 90%;
}

.formBody {
  height: 200px !important;
  border: dashed !important;
  display: grid;
  margin-bottom: 20px;
}

form .input {
  position: relative;
  opacity: 0;
  z-index: 99;
  height: 200px;
}

form .filelabel {
  text-align: center;
  margin-top: -109px;
}

.fileError {
  color: red;
}

.actionAttactmentButton {
  display: flex;
  justify-content: flex-end;
}

.actionAttactmentButton button {
  border-radius: 20px !important;
  margin-top: 45px;
}
