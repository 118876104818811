.home {
    color: #fff;
}
  
.textFields {
    margin: 12px 0 !important;
}
  
.actionButton {
    display: flex;
    justify-content: flex-end;
}
  
.error {
    color: red !important;
    margin: 0 !important;
    margin-left: 8px !important;
}
  
.actionButton button {
    border-radius: 20px !important;
}

.leftMargin {
    margin-left: 8px;
}

.editor{
    min-height: 280px !important;
}
