.parentTd {
  text-align: right !important;
  border: 1px solid !important;
  padding: 0% !important;
}

.parentTd div {
  border-bottom: 1px solid !important;
}

.tableBodyTd {
  width: 80px !important;
}

.parentTd td {
  border: 1px solid !important;
}

.tableRow {
  text-align: right !important;
  padding: 0% !important;
}

.tableRow tr {
  border-bottom: 2px solid !important;
  width: 100%;
}

.tableRow tr td {
  padding: 0;
  width: 120px !important;
  text-align: right !important;
  text-transform: capitalize;
}

.tableRow tr td:first-child {
  text-align: left !important;
  font-size: 16px !important;
  width: 120px !important
}

.appbar {
  background-color: #095c75;
}

.title {
  flex-grow: 1;
  font-weight: initial;
  text-transform: capitalize;
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 45px !important;
}

.rightAction {
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
}

.cellheader {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tableTitle {
  display: flex;
  justify-content: space-between;
}

.tileBody {
  display: flex;
  align-items: center;
  height: 30px;
  margin-top: 8px;
  margin-left: 5px;
}

.groupFields {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.groupFields .textFields {
  width: 274px !important;
  margin: 5px !important
}

.groupFields .amountBody {
  width: 274px !important;
  margin: 5px !important;
}

.amountBody input {
  text-align: right !important;
}

.label {
  margin-left: 8px !important;
  font-size: 16px;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.actionButton button {
  border-radius: 20px !important;
}

.actionButtonHoverable {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 65px;
  margin-top: -5px !important;
  border-bottom: none !important;
  margin-left: -10px;
  opacity: 0;
  z-index: 10;
}

.tableCellbody:hover .actionButtonHoverable {
  opacity: 1;
}

.tableCell:hover + .actionButtonHoverable {
  opacity: 1 !important;
  background-color: red;
}

.textLabel {
  text-transform: capitalize;
  font-size: 16px !important;
  width: 100px;
}
