.detailList {
  display: flex;
  font-size: 16px;
}

.detailList div:first-child {
  min-width: 255px;
}

.detailList div:last-child {
  text-transform: capitalize;
}

.avatar {
  height: 100px !important;
  width: 130px !important;
  margin-right: -20px !important;
}

.headers {
  display: flex;
  align-items: center;
  font-size: 23px;
}

.headers :first-child {
  width: 200px;
}


.detailsBody {
  display: flex;
  font-size: 16px;
}

.leftShowDetails {
  width: 50%;
  margin-right: 10px;
}

.rightShowDetails {
  width: 50%;
}

.showTitleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}