.home {
  color: #fff;
}

.textFields {
  margin: 12px 0 !important;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.error {
  color: red !important;
  margin: 0 !important;
  margin-top: -8px !important;
}

.actionButton button {
  margin-top: 10px;
  border-radius: 20px !important;
}

.actionButton button:first-child {
  margin-right: 15px;
}

.flex {
  display: flex;
}

.groupFields {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.groupFields .textFields {
  width: 300px !important;
}

.groupFieldsRegister {
  display: flex;
  justify-content: space-between;
}

.groupFieldsRegister div {
  width: 270px;
}

.RegistryField {
  margin: 12px 0 !important;
}

.journalTab {
  margin-top: -30px;
}

.checkDetailsHeader {
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.showTitleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.showTitleHeader h2 {
  margin: 0;
}

.detailsBody {
  display: flex;
  font-size: 16px;
}

.leftShowDetails {
  width: 100%;
  margin-right: 10px;
  display: flex;
}

.detailList {
  display: flex;
  margin-right: 90px;
}

.valueDeduction div {
  text-align: right;
  margin: 5px;
  font-size: 16px;
}

.deductionBody div {
  margin: 5px;
  font-size: 16px;
}

.detailList div:first-child {
  min-width: 200px;
}

.employeeDetailBody .detailList {
  display: flex;
  margin-right: 0 !important;
  font-size: 16px;
}

.positionBody {
  display: flex;
}

.positionBody div:first-child {
  min-width: 0 !important;
}

.employeeDetailBody .detailList div:first-child {
  min-width: 100px;
}

.departmentCheckBox {
  display: flex;
  justify-content: space-between;
}

.textFieldAmountHeader {
  text-align: right !important;
  margin-top: 10px !important;
}

.textFieldAmountHeader input {
  text-align: right !important;
}

.adjustmentBody h2 {
  margin: 0 !important;
}

.employeeLabel {
  background: #efefef;
  padding: 10px;
  text-align: center;
}

.valueDeduction {
  text-align: right;
}

.totalDeduction {
  font-weight: bold;
  color: red;
}

.totalNetPay {
  font-weight: bold;
  color: green;
  border-bottom: 6px double #8b0000;
  border-top: 2px solid #8b0000;
}

.totalNetPayValue {
  font-weight: bold;
  color: green;
}

.amount {
  width: 200px;
  text-align: right !important;
}

.amount input {
  text-align: right !important;
}

.totalCell {
  width: 300px;
  text-align: right !important;
}

.value .detailList {
  margin-bottom: 10px;
  margin-top: 10px;
}

.disable {
  pointer-events: none !important;
}

.noDisplay{
  display: none;
}

.tableBodyHeight {
  margin: 10px 20px;
}

.showDisplayLabel {
  margin-top: -20px;
  margin-bottom: 5px;
}
